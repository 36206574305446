















import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Contact from "@/entity/Contact";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import ContactsService from "@/services/ContactsService";
import { booleanTextValue } from "@/utils/string";

@Component({
  components: {
    ActionButtonWithTooltip,
  },
})
export default class NdaSentCell extends Vue {
  @Prop({ required: true, type: Object }) contact!: Contact;
  @Prop({ default: true, type: Boolean }) showText!: boolean;

  isSendingNda = false;

  async sendNda(): Promise<void> {
    if (this.contact?.nda?.sent) {
      const didConfirm = await this.$confirm(
        this.$tc("confirmations.ndaAlreadySent")
      );
      if (!didConfirm) return;
    }

    try {
      this.isSendingNda = true;
      await ContactsService.sendNda(this.contact.id as number);
      this.onNdaSent();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSend"));
    } finally {
      this.isSendingNda = false;
    }
  }

  get canSendNda(): boolean {
    const mail = this.contact?.email;
    if (mail === undefined) {
      return false;
    }
    return mail.length > 0;
  }

  get ndaText() {
    if (this.contact.nda) {
      return booleanTextValue(this.contact.nda.sent);
    }
    return booleanTextValue(false);
  }

  @Emit("nda-sent")
  onNdaSent() {
    return this.contact.id;
  }
}
