














































































import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { FilterBy } from "@/components/data-table/types";
import { ReferenceItem } from "@/services/types";
import RangeSlider from "@/components/form/input/RangeSlider.vue";
import { DEFAULT_MONEY_RANGE_VALUES, CURRENCY } from "@/constants";
import { formatPriceRange } from "@/utils/string";

@Component({
  components: {
    RangeSlider,
  },
})
export default class BuyersFilter extends Vue {
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @Prop({ default: null }) defaultRegion!: number | null;
  @Prop({ type: Array }) defaultIndustries!: ReferenceItem[] | null;
  @Prop({ type: Array }) defaultSubIndustries!: ReferenceItem[] | null;
  @Prop({ default: 0 }) salesPricePublished!: number;
  selectedIndustry: number[] = [];
  selectedSubIndustry: number[] = [];
  selectedRegion: number | null = null;
  selectedRange: [number, number] = [0, 0];
  selectedBuyerStatus: string | null = null;
  steps = DEFAULT_MONEY_RANGE_VALUES;

  resetToDefault() {
    const topRange = this.topRangeDefault(this.salesPricePublished);
    this.selectedIndustry = this.industriesArray(this.defaultIndustries);
    this.selectedSubIndustry = this.industriesArray(this.defaultSubIndustries);
    this.selectedRegion = this.defaultRegion;
    this.selectedRange = [topRange === 0 ? 0 : topRange - 1, topRange];
  }

  topRangeDefault(salesPricePublished: number): number {
    const closestNumber = this.steps.reduce(function (prev, curr) {
      return Math.abs(curr - salesPricePublished) <
        Math.abs(prev - salesPricePublished)
        ? curr
        : prev;
    });
    return this.steps.findIndex((step) => step === closestNumber);
  }

  get rangePrice(): string {
    if (this.selectedRange) {
      return formatPriceRange(
        this.steps[this.selectedRange[0]],
        this.steps[this.selectedRange[1]],
        CURRENCY
      );
    }
    return "";
  }

  get statusOptions() {
    return [
      {
        name: this.$tc("interested"),
        value: "isInterested",
      },
      {
        name: this.$tc("expressionOfInterest"),
        value: "filledExpressionOfInterest",
      },
      {
        name: this.$tc("offer"),
        value: "purchaseOffer",
      },
    ];
  }

  get filter(): FilterBy {
    const isRangeEmpty = !this.selectedRange;
    const filter: FilterBy = {};

    if (this.selectedRegion) {
      filter["regionId"] = this.selectedRegion.toString();
    }

    if (this.selectedIndustry && this.selectedIndustry.length > 0) {
      filter["contactBranchIds"] = this.selectedIndustry.toString();
    }

    if (this.selectedSubIndustry && this.selectedSubIndustry.length > 0) {
      filter["contactSubbranchIds"] = this.selectedSubIndustry.toString();
    }

    if (this.selectedBuyerStatus) {
      filter["buyerStatus"] = this.selectedBuyerStatus.toString();
    }

    if (!isRangeEmpty) {
      filter["salesPricePublished"] = [
        this.steps[this.selectedRange[0]],
        this.steps[this.selectedRange[1]],
      ].toString();
    }

    return filter;
  }

  industriesArray(industries: ReferenceItem[] | null): number[] {
    if (industries == null) return [];
    return industries.map((industry) => industry.id);
  }

  @Watch("filter", { immediate: true })
  async onFilterChange(): Promise<void> {
    this.$emit("change", this.filter);
  }

  created() {
    this.resetToDefault();
  }

  resetField(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }
}
