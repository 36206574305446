








































import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import { debounce } from "lodash";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import SectionHeader from "@/components/project-buyers-form/SectionHeader.vue";
import Contact, { getContactFullName } from "@/entity/Contact";
import { FilterBy, ITableHeader } from "@/components/data-table/types";
import BuyersFilter from "@/components/project-buyers-form/BuyersFilter.vue";
import Project from "@/entity/Project";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    SectionHeader,
    BuyersFilter,
  },
})
export default class RelevantProfiles extends Mixins() {
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @Prop() project!: Project;
  isSectionActive = false;
  contacts: Contact[] = [];
  isFetchingContacts = false;
  debounceSearchProfiles = debounce(this.fetchRelevantBuyers, 200);

  fullContactName = getContactFullName;

  get tableHeaders(): ITableHeader[] {
    return [
      {
        value: "name",
        text: this.$tc("name"),
      },
      {
        value: "actions",
        text: this.$tc("action", 2),
      },
    ];
  }

  deleteContact(contact: Contact): void {
    const index = this.contacts.findIndex(
      (selectedContact) => contact.id === selectedContact.id
    );
    this.contacts.splice(index, 1);
  }

  async fetchRelevantBuyers(filter: FilterBy): Promise<void> {
    try {
      this.isFetchingContacts = true;
      this.contacts = await ProjectBuyersService.relevant(
        this.$route.params.projectId,
        {
          itemsPerPage: ALL_ITEMS_PER_PAGE,
          filterBy: {
            ...filter,
            isBlocked: false,
          },
        }
      );
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isFetchingContacts = false;
    }
  }

  onFilterChanged(filter: FilterBy): void {
    this.debounceSearchProfiles(filter);
  }

  get selectedIds(): number[] {
    if (this.isSectionActive) {
      return this.contacts.map((contact) => contact.id as number);
    }
    return [];
  }

  @Watch("selectedIds")
  onSelectedIdsChange(selectedIds: number[]): void {
    this.$emit("change", selectedIds);
  }
}
