
























































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import SectionHeader from "@/components/project-buyers-form/SectionHeader.vue";
import Contact, { getContactFullName } from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import { State } from "vuex-class";
import { ITableHeader } from "@/components/data-table/types";
import Project from "@/entity/Project";
import { get } from "lodash";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    SectionHeader,
  },
})
export default class ManualAddExternal extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;

  isSectionActive = false;
  isFetchingContacts = false;
  contacts: Contact[] = [];
  selectedIndustry: number[] = [];
  fullContactName = getContactFullName;

  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];

  get tableHeaders(): ITableHeader[] {
    return [
      {
        value: "name",
        text: this.$tc("name"),
      },
      {
        value: "contactBranches",
        text: this.$tc("industry"),
      },
      {
        value: "region.name",
        text: this.$tc("region"),
      },
      {
        value: "actions",
        text: this.$tc("action", 2),
      },
    ];
  }

  deleteContact(contact: Contact): void {
    const index = this.contacts.findIndex(
      (selectedContact) => contact.id === selectedContact.id
    );
    this.contacts.splice(index, 1);
  }

  get selectedIds(): number[] {
    if (this.isSectionActive) {
      return this.contacts.map((contact) => contact.id as number);
    }
    return [];
  }

  async fetchContacts(): Promise<void> {
    try {
      this.isFetchingContacts = true;
      const response = await ContactsService.find({
        itemsPerPage: ALL_ITEMS_PER_PAGE,
        filterBy: {
          notInBuyersListForProject: this.$route.params.projectId,
          isProspectiveBuyer: false,
          hasSearchProfile: false,
          isExternal: true,
          isBlocked: false,
          isDisabled: false,
          contactBranchIds: this.selectedIndustry.toString(),
        },
      });
      this.contacts = response.content;
    } finally {
      this.isFetchingContacts = false;
    }
  }

  preSetBranches(): void {
    this.selectedIndustry = get(
      this.project,
      "projectContact.contactBranchIds",
      ""
    )
      .split(",")
      .map((id: string) => parseInt(id));
  }

  @Watch("selectedIds")
  onSelectedIdsChange(selectedIds: number[]): void {
    this.$emit("change", selectedIds);
  }

  @Watch("isSectionActive", { immediate: true })
  async onIsSectionActiveChange(isSectionActive: boolean): Promise<void> {
    if (!isSectionActive) {
      this.$emit("change", []);
    } else {
      await this.fetchContacts();
      this.preSetBranches();
      this.$emit("change", this.selectedIds);
    }
  }

  created() {
    this.preSetBranches();
  }
}
