import Company from "@/entity/Company";
import { Location } from "vue-router";

export default (company: Company): Location | null => {
  if (!company || !company?.id) {
    return null;
  }
  if (company.id) {
    return {
      name: "companyDetail",
      params: {
        companyId: company.id.toString(),
      },
    };
  }
  return null;
};
