

























import { Vue, Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class ShareUrlDialog extends Vue {
  @Prop({ required: true, type: String }) url!: string;
  @PropSync("isDialogOpened", { required: true, type: Boolean })
  isDialogOpenedV!: string;

  copyUrl() {
    const urlInput = document.getElementById("url") as HTMLInputElement;

    if (urlInput) {
      urlInput.select();
      document.execCommand("copy");
      this.$snackbarSuccess(this.$tc("urlCopied"));
    }
  }
}
