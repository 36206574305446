































import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import RulesMixin from "@/mixins/RulesMixin";
import FileManagerService from "@/services/FileManagerService";
import { getContactFullName } from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";

@Component({
  components: {
    FormCard,
  },
})
export default class NdaForm extends Mixins<BaseForm<File>, RulesMixin>(
  BaseForm,
  RulesMixin
) {
  data: File | null = null;
  contactFullName = "";

  async submitForm(): Promise<void> {
    if (this.data !== null) {
      await FileManagerService.uploadNdaAsConsultant(this.data, this.entityId);
    }
  }

  async loadForm(): Promise<void> {
    const response = await ContactsService.findOne(this.entityId);
    this.contactFullName = getContactFullName(response);
  }

  created(): void {
    this.handleFormLoad(this.loadForm);
  }
}
