










































































import { Component, Mixins, Prop } from "vue-property-decorator";
import BuyerForm from "@/components/project-buyers-form/ProjectBuyerForm.vue";
import { TableQuery } from "@/components/data-table/types";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ProjectBuyer from "@/entity/ProjectBuyer";
import BuyersListForm from "@/components/project-buyers-form/BuyersListForm.vue";
import Contact from "@/entity/Contact";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DialogMixin from "@/mixins/DialogMixin";
import BuyersActionDialog from "@/components/project-buyers/BuyersActionDialog.vue";
import { State } from "vuex-class";
import Notification from "@/entity/Notification";
import Project from "@/entity/Project";
import { ReferenceItem } from "@/services/types";
import ExpressionOfInterestForm from "@/components/project-buyers/expression-of-interest/ExpressionOfInterestForm.vue";
import ProjectBuyersDataTable from "@/components/data-tables/ProjectBuyersDataTable.vue";
import ExportProjectBuyers from "@/components/project-buyers/ExportProjectBuyers.vue";
import * as XLSX from "xlsx";
import { formatUnixTimestamp } from "@/utils/date";
import { booleanOrNaTexValue } from "@/utils/string";

@Component({
  components: {
    BuyersListForm,
    BuyerForm,
    BuyersActionDialog,
    ExpressionOfInterestForm,
    ProjectBuyersDataTable,
    ExportProjectBuyers,
  },
})
export default class ProjectBuyersView extends Mixins(
  TranslatedEnumListsMixin,
  DialogMixin
) {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Boolean }) disabledActionForNonAdmin!: boolean;

  buyers: ProjectBuyer[] = [];
  showSkeleton = true;
  isTableLoading = false;
  options: TableQuery = {};
  itemsLength = 0;
  contacts: Contact[] = [];
  polling: null | any = null;
  activeBuyer: ProjectBuyer | null = null;
  selectedBuyers: ProjectBuyer[] = [];

  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];
  @State("notifications", { namespace: "notifications" })
  notifications!: Notification[];

  get fullOptions() {
    return {
      ...this.options,
      sortBy: {
        ...this.options.sortBy,
        id: false,
      },
      filterBy: {
        ...this.options.filterBy,
        "contact.isDisabled": false,
      },
    };
  }

  async fetchBuyers() {
    try {
      this.isTableLoading = true;
      const response = await ProjectBuyersService.find(
        this.project.id as number,
        this.fullOptions
      );
      this.buyers = response.content
        .map((buyer) => {
          if (
            buyer.nda ||
            (buyer?.contact?.companies && buyer?.contact?.companies?.length > 0)
          ) {
            const updatedBuyer = { ...buyer };
            if (buyer.nda && updatedBuyer.contact) {
              updatedBuyer.contact.nda = {
                approved: buyer.nda.approved,
                sent: buyer.nda.sent,
                signed: buyer.nda.signed,
              };
              updatedBuyer.contact.ndaApproved = buyer.nda.approved;
              updatedBuyer.contact.ndaSent = buyer.nda.sent;
              updatedBuyer.contact.ndaSigned = buyer.nda.signed;
            }
            if (buyer?.contact?.companies) {
              updatedBuyer.company = buyer.contact.companies[0];
            } else updatedBuyer.company = null;
            return updatedBuyer;
          }

          return buyer;
        })
        .filter((item) => item.contact);
      this.itemsLength = response.totalItems;

      this.updateSelectedBuyers(this.buyers);
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isTableLoading = false;
    }
  }

  updateSelectedBuyers(newBuyers: ProjectBuyer[]) {
    newBuyers.forEach((newBuyer) => {
      const existingIndex = this.selectedBuyers.findIndex(
        (buyer) => buyer.id === newBuyer.id
      );

      if (existingIndex > -1) {
        this.selectedBuyers.splice(existingIndex, 1, newBuyer);
      }
    });
  }

  async fetchBuyerContacts() {
    this.contacts = await ProjectBuyersService.findAllBuyerContacts(
      this.project.id as number
    );
  }

  async deleteBuyer(buyerId: number): Promise<void> {
    try {
      this.isTableLoading = true;
      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (!didConfirm) {
        return;
      }

      await ProjectBuyersService.delete(this.project.id as number, buyerId);
      this.fetchBuyers();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isTableLoading = false;
    }
  }

  async created() {
    await this.fetchBuyerContacts();
    this.showSkeleton = false;
  }

  async deleteAllSelected() {
    try {
      this.isTableLoading = true;
      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (!didConfirm) {
        return;
      }

      const deletePromise = this.selectedBuyers.map((buyer) =>
        ProjectBuyersService.delete(
          this.project.id as number,
          buyer.id as number
        )
      );

      await Promise.all(deletePromise);
      this.selectedBuyers = [];
      this.fetchBuyers();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isTableLoading = false;
    }
  }

  onFormSuccess() {
    this.closeDialog();
    this.fetchBuyers();
    this.fetchBuyerContacts();
  }

  onOptionsChange(options: TableQuery): void {
    clearInterval(this.polling);
    this.options = options;
    this.fetchBuyers();
    this.polling = setInterval(() => this.fetchBuyers(), 30000);
  }

  closeActionDialogAfterSuccess(): void {
    this.closeDialog();
    this.selectedBuyers = [];
    this.fetchBuyers();
  }

  onRowClick(buyer: ProjectBuyer): void {
    this.openDialog("editBuyer", buyer.id);
  }

  onAddItemClicked(): void {
    this.openDialog("addBuyers");
  }

  openEOIDialog(buyer: ProjectBuyer): void {
    this.activeBuyer = buyer;
    this.openDialog("eoi", buyer.expressionOfInterest?.id);
  }

  openActionDialog(type: string): void {
    this.openDialog("actionDialog", type);
  }

  openExportDialog(): void {
    this.openDialog("export");
  }

  beforeDestroy(): void {
    clearInterval(this.polling);
  }

  async handleExport(filename: string): Promise<any> {
    const response = await ProjectBuyersService.find(
      this.project.id as number,
      { ...this.fullOptions, itemsPerPage: 9999999 }
    );
    const finalData = response.content.map((buyer) => ({
      [this.$tc("priority", 1)]: booleanOrNaTexValue(buyer.isPriority),
      [this.$tc("firstName")]: buyer.contact?.lastName,
      [this.$tc("name")]: buyer.contact?.firstName,
      [this.$tc("email")]: buyer.contact?.email,
      [this.$tc("companyName")]: buyer.company?.name,
      [this.$tc("phoneMobile")]: buyer.contact?.phoneMobile,
      [this.$tc("status")]: this.$tc(buyer.status),
      [this.$tc("buyerType")]: buyer.contact?.buyerType?.name,
      [this.$tc("lastStatusChange")]: formatUnixTimestamp(
        buyer.statusUpdatedAt
      ),
      [this.$tc("ndaSigned")]: buyer.nda?.signed ? "Ja" : "Nein",
      [this.$tc("ndaSent")]: buyer.nda?.sent ? "Ja" : "Nein",
      [this.$tc("exposeSent")]: booleanOrNaTexValue(buyer.sentExpose),
      [this.$tc("expressionOfInterest")]: booleanOrNaTexValue(
        buyer.filledExpressionOfInterest
      ),
      [this.$tc("interested")]: booleanOrNaTexValue(buyer.interested),
      [this.$tc("offer")]: booleanOrNaTexValue(buyer.purchaseOffer),
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      this.$tc("prospectiveBuyer", 1)
    );

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }
}
