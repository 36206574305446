






















































































































































import { Component, Mixins } from "vue-property-decorator";
import ProjectBuyer, {
  BuyerStatus,
  projectBuyerToUpdate,
  PurchaseOfferData,
  UpdateBuyer,
} from "@/entity/ProjectBuyer";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import RulesMixin from "@/mixins/RulesMixin";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import { getContactFullName } from "@/entity/Contact";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import WebsiteApiService from "@/services/WebsiteApiService";
import { OptionItem, ReferenceItem } from "@/services/types";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";

@Component({
  components: {
    FormCard,
    CurrencyInput,
  },
})
export default class ProjectBuyerForm extends Mixins<
  RulesMixin,
  BaseForm<UpdateBuyer>,
  TranslatedEnumListsMixin
>(RulesMixin, BaseForm, TranslatedEnumListsMixin) {
  data: UpdateBuyer = {
    interested: false,
    isPriority: false,
    status: BuyerStatus.selected,
    dismissedStatusId: null,
    dismissedStatusComment: null,
    salesPrice: null,
    fixedPrice: null,
    earnOutMax: null,
    ownerLoan: null,
  };

  initialPurchaseOfferData = {
    salesPrice: null,
    fixedPrice: null,
    earnOutMax: null,
    ownerLoan: null,
  } as PurchaseOfferData;

  rejectionReason: ReferenceItem[] = [];
  purchaseOffer = false;
  contactName = "";

  get isStatusOfferReceived(): boolean {
    return this.data.status === BuyerStatus.offerReceived;
  }

  get allowedBuyerStatuses(): OptionItem[] {
    return this.translatedEnums.buyerStatuses.filter((status) => {
      return status.value !== BuyerStatus.ndaSent;
    });
  }

  async submitForm(): Promise<void> {
    if (this.isEditing) {
      await ProjectBuyersService.update(
        this.$route.params.projectId,
        this.entityId,
        this.data
      );
    }
  }

  async loadBuyer(): Promise<void> {
    if (this.entityId) {
      const response = await ProjectBuyersService.findOne(
        this.$route.params.projectId,
        this.entityId
      );

      this.data = projectBuyerToUpdate(response);
      this.purchaseOffer = response.purchaseOffer;
      this.setInitialOfferData(response);
      this.contactName = getContactFullName(response.contact);
    }
  }

  setInitialOfferData(buyer: ProjectBuyer) {
    this.initialPurchaseOfferData.earnOutMax = buyer.earnOutMax;
    this.initialPurchaseOfferData.salesPrice = buyer.salesPrice;
    this.initialPurchaseOfferData.fixedPrice = buyer.fixedPrice;
    this.initialPurchaseOfferData.ownerLoan = buyer.ownerLoan;
  }

  resetOfferData() {
    this.data.earnOutMax = this.initialPurchaseOfferData.earnOutMax;
    this.data.salesPrice = this.initialPurchaseOfferData.salesPrice;
    this.data.fixedPrice = this.initialPurchaseOfferData.fixedPrice;
    this.data.ownerLoan = this.initialPurchaseOfferData.ownerLoan;
  }

  get offerDataInputRules() {
    return this.data.status === BuyerStatus.offerReceived
      ? [this.rules.required]
      : [];
  }

  get offerDataInputClass() {
    return this.data.status === BuyerStatus.offerReceived ? "required" : "";
  }

  async loadForm(): Promise<void> {
    [this.rejectionReason] = await Promise.all([
      WebsiteApiService.getTeaserDismissedStatuses(),
      this.loadBuyer(),
    ]);
  }

  onBuyerStatusChange(value: string) {
    this.data.dismissedStatusId = null;

    if (value === BuyerStatus.notInterested) {
      this.data.interested = false;
    }

    if (value !== BuyerStatus.offerReceived) {
      this.resetOfferData();
    }
  }

  get showRejectionReasonSelection(): boolean {
    return this.data.status === BuyerStatus.dismissed;
  }

  get disableInterestedSelection(): boolean {
    return this.data.status === BuyerStatus.notInterested;
  }

  created(): void {
    this.handleFormLoad(this.loadForm);
  }
}
