import Axios from "axios";
import { ReferenceItem } from "@/services/types";
import ExpressionOfInterest, {
  BuyersExpressionOfInterest,
  CreateExpressionOfInterest,
  ExpressionOfInterestFormQuestion,
} from "@/entity/ExpressionOfInterest";
import ProjectBuyer from "@/entity/ProjectBuyer";

export default class WebsiteApiService {
  static async teaserYes(token: string): Promise<void> {
    const response = await Axios.post(
      "/api/v2/forms/teaser/yes",
      {},
      {
        params: {
          token: token,
        },
      }
    );
    return response.data;
  }

  static async teaserNo(token: string): Promise<void> {
    const response = await Axios.post(
      "/api/v2/forms/teaser/no",
      {},
      {
        params: {
          token: token,
        },
      }
    );
    return response.data;
  }

  static async getTeaserDismissedStatuses(): Promise<ReferenceItem[]> {
    const response = await Axios.get("/api/v2/forms/teaser/dismissedStatuses");
    return response.data;
  }

  public static async uploadExpressionOfInterest(
    token: string,
    data: CreateExpressionOfInterest
  ): Promise<ExpressionOfInterest> {
    const response = await Axios.post("/api/v2/forms/eoi", data, {
      params: {
        token,
      },
    });
    return response.data;
  }

  public static async uploadExpressionOfInterestV2(
    token: string,
    data: { questionId: number; value: string }[]
  ): Promise<number> {
    const response = await Axios.post("/api/v2/forms/eoi-v2", data, {
      params: {
        token,
      },
    });
    return response.data;
  }

  public static async getExpressionOfInterest(
    token: string
  ): Promise<ExpressionOfInterest> {
    const response = await Axios.get("/api/v2/forms/eoi", {
      params: {
        token,
      },
    });
    return response.data;
  }

  public static async getExpressionOfInterestV2(
    token: string
  ): Promise<ExpressionOfInterestFormQuestion[]> {
    const response = await Axios.get("/api/v2/forms/eoi-v2", {
      params: {
        token,
      },
    });
    return response.data;
  }

  static async uploadEoiFile(token: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post("/api/v2/files/eoi-v2/upload", formData, {
      params: {
        token,
      },
    });
    return response.data;
  }

  public static async getBuyersExpressionOfInterestByToken(
    token: string
  ): Promise<BuyersExpressionOfInterest> {
    const response = await Axios.get("/api/v2/forms/eoi-v2/shared", {
      params: {
        token,
      },
    });
    return response.data;
  }

  public static async getBuyerFromEoiToken(
    token: string
  ): Promise<ProjectBuyer> {
    const response = await Axios.get("/api/v2/forms/buyer", {
      params: {
        token,
      },
    });
    return response.data;
  }
}
