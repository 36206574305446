























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import ProjectBuyer, { canSendEoi } from "../../entity/ProjectBuyer";
import Project from "@/entity/Project";
import { isEmpty } from "lodash";

@Component
export default class BuyersSelectedActions extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ default: () => [] }) selected!: ProjectBuyer[];
  @Prop({ required: true }) colNum!: number;
  @Prop({ default: false }) isMobile!: boolean;

  get enableSendExpose(): boolean {
    if (!this.project.exposeFileExists) {
      return false;
    }

    return this.selected.every((buyer) => {
      return buyer.contact !== null && buyer.contact.ndaApproved;
    });
  }

  get enableExposeFollowup(): boolean {
    if (!this.project.exposeFileExists) {
      return false;
    }

    return this.selected.every((buyer) => {
      return buyer.contact !== null && buyer.sentExpose;
    });
  }

  get isTeaserDisabled(): boolean {
    return isEmpty(this.project.teaserTitle) || isEmpty(this.project.teaserUrl);
  }

  get enableExpressionOfInterest(): boolean {
    return this.selected.every(canSendEoi);
  }
}
