











import { Vue, Component, Prop } from "vue-property-decorator";
import { DEFAULT_MONEY_RANGE_VALUES } from "@/constants";

@Component
export default class RangeSlider extends Vue {
  @Prop({ default: () => [0, 0] }) value!: [number, number];
  @Prop({ default: () => [...DEFAULT_MONEY_RANGE_VALUES] }) steps!: number[];

  /**
   * If user moves both sliders to same step move
   * move the right one to the right to they are not same
   * If both are moved to last step then move the left slider to the last - 1
   */
  onSliderMovementEnd(value: [number, number]): void {
    let valueToEmit = [...value];
    if (value[0] === value[1]) {
      if (this.isAtLastStep(value[0]) && this.isAtLastStep(value[1])) {
        valueToEmit = [this.steps.length - 2, this.steps.length - 1];
      } else {
        valueToEmit = [value[0], value[1] + 1];
      }
    }
    this.$emit("input", valueToEmit);
  }

  isAtLastStep(step: number) {
    return step === this.steps.length - 1;
  }
}
