
























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import ProjectBuyer from "@/entity/ProjectBuyer";
import FormCard from "@/components/form/FormCard.vue";
import Contact, { getContactFullName } from "@/entity/Contact";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ContactsService from "@/services/ContactsService";

@Component({
  components: {
    FormCard,
  },
})
export default class BuyersActionDialog extends Vue {
  @Prop({ required: true }) type!: string;
  @Prop({ required: true, default: () => [] }) value!: ProjectBuyer[];

  buyerList: ProjectBuyer[] = [];
  isSaving = false;
  isEditEmailOpened = false;
  hoverIndex: number | null = null;

  get title(): string {
    switch (this.type) {
      case "nda": {
        return this.$t("requestNdaFrom", {
          amount: this.buyerList.length,
        }) as string;
      }
      case "expose": {
        return this.$t("sendExposeTo", {
          amount: this.buyerList.length,
        }) as string;
      }
      case "teaser": {
        return this.$t("sendTeaserTo", {
          amount: this.buyerList.length,
        }) as string;
      }
      case "exposeFollowup": {
        return this.$t("sendExposeFollowupTo", {
          amount: this.buyerList.length,
        }) as string;
      }
      default: {
        return "";
      }
    }
  }

  async sendTeaser(): Promise<void> {
    try {
      this.isSaving = true;
      await ProjectBuyersService.sendTeaser(
        this.$route.params.projectId,
        this.buyerList
      );
      this.$emit("success");
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
      this.$emit("cancel");
    } finally {
      this.isSaving = false;
    }
  }

  async sendExpose(): Promise<void> {
    try {
      this.isSaving = true;
      await ProjectBuyersService.sendExpose(
        this.$route.params.projectId,
        this.buyerList
      );
      this.$emit("success");
    } catch (e) {
      if (e.response.status === 404) {
        this.$snackbarError(this.$tc("apiErrors.exposeFileMissing"));
        return;
      }

      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isSaving = false;
    }
  }

  async sendExposeFollowup(): Promise<void> {
    try {
      this.isSaving = true;
      await ProjectBuyersService.sendExposeFollowup(
        this.$route.params.projectId,
        this.buyerList
      );
      this.$emit("success");
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isSaving = false;
    }
  }

  async sendExpressionOfInterest(): Promise<void> {
    try {
      this.isSaving = true;
      await ProjectBuyersService.sendExpressionOfInterest(
        this.$route.params.projectId,
        this.buyerList
      );
      this.$emit("success");
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isSaving = false;
    }
  }

  async sendNda(): Promise<void> {
    try {
      this.isSaving = true;
      const needConfirmation = this.buyerList.some(
        (buyer) => buyer.contact?.ndaSigned ?? false
      );

      if (needConfirmation) {
        const didConfirm = await this.$confirm(
          this.$tc("confirmations.ndaAlreadySentGroup")
        );
        if (!didConfirm) {
          return;
        }
      }

      const ndaPromises = this.buyerList.map((buyer) => {
        return ContactsService.sendNda(
          buyer.contact?.id as number,
          buyer.contact?.email
        );
      });

      await Promise.all(ndaPromises);
      this.$emit("success");
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSend"));
    } finally {
      this.isSaving = false;
    }
  }

  get isNdaDialog(): boolean {
    return this.type === "nda";
  }

  getFullName(contact: Contact): string {
    return getContactFullName(contact);
  }

  created() {
    this.buyerList = cloneDeep(this.value);
  }
}
