




















import { Vue, Component, Prop } from "vue-property-decorator";
import ExpressionOfInterest from "@/entity/ExpressionOfInterest";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ShareUrlDialog from "@/components/project-buyers/expression-of-interest/ShareUrlDialog.vue";

@Component({
  components: {
    ShareUrlDialog,
  },
})
export default class ExpressionOfInterestVersions extends Vue {
  @Prop({ required: true, type: Number }) projectId!: number;
  @Prop({ required: true, type: Number }) buyerId!: number;

  showSkeleton = true;
  expressionsOfInterest: ExpressionOfInterest[] = [];
  shareUrlDialogOpened = false;
  shareUrl = "";

  async getEoiShareLink(): Promise<void> {
    const response = await ProjectBuyersService.getExpressionOfInterestLinkV2(
      this.projectId,
      this.buyerId
    );
    this.shareUrl = response.url;
    this.shareUrlDialogOpened = true;
  }

  async loadExpressionsOfInterest(): Promise<void> {
    try {
      const response =
        await ProjectBuyersService.getAllBuyersExpressionsOfInterest(
          this.projectId,
          this.buyerId
        );

      this.expressionsOfInterest = response.reverse();
    } catch (error) {
      if (error.response.status !== 404) {
        throw error;
      }
    }
  }

  async created() {
    await this.loadExpressionsOfInterest();
    this.showSkeleton = false;
  }
}
