



















































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import Project, { ProjectType } from "@/entity/Project";
import Contact, { getContactFullName } from "@/entity/Contact";
import { ITableHeader } from "@/components/data-table/types";
import SectionHeader from "@/components/project-buyers-form/SectionHeader.vue";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ProjectsService from "@/services/ProjectsService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";

@Component({
  components: {
    SectionHeader,
  },
})
export default class FromProject extends Vue {
  @Prop() project!: Project;
  isSectionActive = false;
  contacts: Contact[] = [];
  projects: Project[] = [];
  isFetchingContacts = false;
  fullContactName = getContactFullName;

  get tableHeaders(): ITableHeader[] {
    return [
      {
        value: "name",
        text: this.$tc("name"),
      },
      {
        value: "actions",
        text: this.$tc("action", 2),
      },
    ];
  }

  deleteContact(contact: Contact): void {
    const index = this.contacts.findIndex(
      (selectedContact) => contact.id === selectedContact.id
    );
    this.contacts.splice(index, 1);
  }

  async fetchContacts(projectIds: number[]): Promise<void> {
    this.reset("projectField");

    if (!Array.isArray(projectIds) || projectIds.length === 0) {
      this.contacts = [];
      return;
    }

    try {
      this.isFetchingContacts = true;
      const response = await ProjectBuyersService.find(
        this.project.id as number,
        {
          itemsPerPage: ALL_ITEMS_PER_PAGE,
          filterBy: {
            projectId: projectIds.toString(),
            "contact.isBlocked": false,
          },
        }
      );
      this.contacts = response.content
        .filter((buyer) => buyer.sentExpose === true)
        .map((buyer) => buyer.contact as Contact);
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isFetchingContacts = false;
    }
  }

  async fetchProjects(): Promise<void> {
    const projects = await ProjectsService.find({
      itemsPerPage: ALL_ITEMS_PER_PAGE,
      filterBy: {
        projectType: ProjectType.transaction,
      },
    });

    this.projects = projects.content
      .filter((item) => item.projectContact)
      .sort((projectA, projectB) => {
        if (
          projectA.projectContact.name.trim() <
          projectB.projectContact.name.trim()
        ) {
          return -1;
        }
        if (
          projectA.projectContact.name.trim() >
          projectB.projectContact.name.trim()
        ) {
          return 1;
        }
        return 0;
      });
  }

  get selectedIds(): number[] {
    if (this.isSectionActive) {
      return this.contacts.map((contact) => contact.id as number);
    }
    return [];
  }

  @Watch("selectedIds")
  onSelectedIdsChange(selectedIds: number[]): void {
    this.$emit("change", selectedIds);
  }

  created() {
    this.fetchProjects();
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }
}
