



















import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import ContactsService from "@/services/ContactsService";

@Component({
  components: {
    FormCard,
  },
})
export default class RejectNdaForm extends Mixins(BaseForm) {
  // Prop entityId here is used for Contact ID
  note = "";

  async onSave() {
    const response = await this.$confirm(
      this.$t("confirmations.rejectNdaSendNew").toString()
    );

    await this.handleFormSubmit(() => this.submitForm(response));
  }

  async submitForm(sendNda: boolean | undefined): Promise<void> {
    await ContactsService.rejectNda(this.entityId, this.note);

    if (sendNda) {
      await ContactsService.sendNda(this.entityId);
    }
  }
}
