import { ReferenceItem } from "@/services/types";
import SearchProfile from "./SearchProfile";
import { ContactIndustry } from "@/entity/Industry";
import Company from "./Company";

export const countryList: ReferenceItem[] = [
  {
    id: 1,
    name: "Schweiz",
  },
  {
    id: 2,
    name: "Deutschland",
  },
  {
    id: 3,
    name: "Österreich",
  },
];

export enum SalutationForm {
  MR = "mr",
  MRS = "mrs",
  FAMILY = "family",
  MR_AND_MRS = "mrAndMrs",
}

export enum ContactTypeId {
  company = 1,
  contact = 2,
}

export interface CreateContact {
  id: number | null;
  firstName: string;
  lastName: string;
  title: string;
  salutationForm: SalutationForm | "";
  email: string;
  phoneMobile: string;
  phoneFixed: string;
  address: Address;
  isBlocked: boolean;
  remarks: string;
  buyerTypeId: null | number;
  addressId: null | number | undefined;
  companies: null | Company[];
  company: null | Company;
}

export function initialOwnerContact() {
  return {
    firstName: "",
    name: "",
    mail: "",
    phoneMobile: "",
    phoneFixed: "",
    contactTypeId: ContactTypeId.contact,
  };
}

export function initialContactData(): CreateContact {
  return {
    id: null,
    salutationForm: "",
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phoneMobile: "",
    phoneFixed: "",
    address: {
      address: "",
      postcode: "",
      city: "",
      country: 0,
    },
    isBlocked: false,
    remarks: "",
    buyerTypeId: null,
    addressId: null,
    companies: null,
    company: null,
  };
}

export function contactToCreateContact(contact: Contact): CreateContact {
  return {
    id: contact.id,
    salutationForm: contact.salutationForm,
    firstName: contact.firstName,
    lastName: contact.lastName,
    title: contact.title,
    email: contact.email,
    phoneMobile: contact.phoneMobile,
    phoneFixed: contact.phoneFixed,
    address: contact.address,
    remarks: contact.remarks,
    buyerTypeId: contact.buyerTypeId,
    isBlocked: contact.isBlocked,
    companies: contact.companies,
    addressId: contact.addressId,
    company: contact.company,
  };
}
export interface Address {
  address?: string;
  postcode?: string;
  city?: string;
  country?: number;
}

export default interface Contact {
  id: number | null;
  firstName: string;
  name: string;
  fullName?: string;
  lastName: string;
  title: string;
  address: Address;
  country: ReferenceItem | null;
  contactBranches: ContactIndustry[] | null;
  subBranches: ReferenceItem[] | null;
  email: string;
  phoneMobile: string;
  phoneFixed: string;
  remarks: string;
  url: string;
  contactTypeId: number;
  nr: string;
  salutationForm: SalutationForm | "";
  ndaSigned: boolean;
  ndaSent: boolean;
  ndaApproved: boolean;
  hasSearchProfile: boolean | null;
  hasProject: boolean | null;
  isExternal: boolean;
  isBlocked: boolean;
  isDisabled: boolean;
  contactId: number | null;
  owner: Contact | null;
  regionId: number | null;
  region: ReferenceItem | null;
  searchProfile: SearchProfile;
  buyerTypeId: number | null;
  buyerType: ReferenceItem | null;
  projectReferenceIds: number[];
  companies: null | Company[];
  company: null | Company;
  employees: number | null;
  companyTypeId: number | null;
  fullTimeEquivalent: string | null;
  companyType: string | null;
  nda?: Nda;
  addressId?: number | null;
}

export interface Nda {
  signed: boolean;
  sent: boolean;
  approved: boolean;
}

export interface CsvDataContact {
  firstName: string;
  lastName: string;
  email: string;
  salutation: SalutationForm | "";
}

export const getContactFullName = (contact: Contact | null): string => {
  if (!contact) {
    return "";
  }

  if (contact.contactTypeId === 1) {
    return contact.name;
  }

  return `${contact.firstName || ""} ${contact.lastName || ""}`;
};

export const getContactCountryName = (contact: Contact): string => {
  const country = countryList.find(
    (country) => country.id === contact?.address?.country
  );

  if (!country) {
    return "";
  }

  return country.name;
};
