










import { Vue, Component, Prop } from "vue-property-decorator";
import { LinkType } from "@/enums";

@Component
export default class Link extends Vue {
  @Prop({ default: LinkType.URL, type: String }) prefix!: string;
  @Prop({ required: true, type: String }) href!: string;
  @Prop({ default: false, type: Boolean }) noPrefix!: boolean;
  @Prop({ default: "_blank", type: String }) target!: string;

  get fullHref(): string {
    if (this.noPrefix) return this.href;

    return `${this.prefix}${this.href}`;
  }
}
