

















































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import Contact from "@/entity/Contact";
import FileManagerService from "@/services/FileManagerService";
import ContactsService from "@/services/ContactsService";
import RejectNdaForm from "@/components/contact/RejectNdaForm.vue";
import DialogMixin from "@/mixins/DialogMixin";
import NdaForm from "@/components/NdaForm.vue";

@Component({
  components: {
    ActionButtonWithTooltip,
    RejectNdaForm,
    NdaForm,
  },
})
export default class NdaSignedCell extends Mixins(DialogMixin) {
  @Prop({ required: true, type: Object }) contact!: Contact;

  openNdaFile = FileManagerService.openNdaFile;

  get ndaFileIconColor(): string {
    return this.contact?.nda?.approved ? "green" : "red";
  }

  async approveNda(): Promise<void> {
    await ContactsService.approveNda(this.contact.id as number);
    this.onNdaApprovedChanged(true);
  }

  onNdaRejected() {
    this.closeDialog();
    this.onNdaApprovedChanged(false);
  }

  @Emit("nda-approved-changed")
  onNdaApprovedChanged(value: boolean) {
    this.contact.ndaApproved = value;
    this.contact.nda = {
      approved: value,
      sent: value,
      signed: value,
    };
    return value;
  }

  @Emit("nda-uploaded")
  onNdaUploaded() {
    this.closeDialog();
    return this.contact.id;
  }

  get isNdaSigned() {
    if (this.contact.nda) {
      return this.contact.nda.signed;
    }
    return false;
  }

  get isNdaApproved() {
    if (this.contact.nda) {
      return this.contact.nda.approved;
    }
    return false;
  }
}
