var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{ 'v-data-table__mobile-table-row': _vm.isMobile }},[_c('td',{class:{ 'v-data-table__mobile-row': _vm.isMobile },attrs:{"colspan":_vm.colNum}},[_c('v-layout',{staticClass:"actions-row align-center"},[_c('v-flex',{attrs:{"grow":""}},[_c('span',{staticClass:"selection"},[_c('v-icon',{attrs:{"left":""},on:{"click":function($event){return _vm.$emit('resetSelection')}}},[_vm._v(" mdi-close ")]),_c('span',{staticClass:"blue--text text--accent-1 font-weight-bold mx-3"},[_vm._v(" "+_vm._s(_vm.selected.length)+" "+_vm._s(_vm.$tc("selected", 1))+" ")])],1),_c('v-btn',{staticClass:"mx-3 text--white",attrs:{"color":"blue accent-1","dark":""},on:{"click":function($event){return _vm.$emit('openActionDialog', 'nda')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-document-edit ")]),_vm._v(" "+_vm._s(_vm.$tc("requestNda", 1))+" ")],1),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.project.exposeFileExists},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-3 text--white",attrs:{"color":"blue accent-1","dark":_vm.enableSendExpose,"disabled":!_vm.enableSendExpose},on:{"click":function($event){return _vm.$emit('openActionDialog', 'expose')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-mail ")]),_vm._v(" "+_vm._s(_vm.$tc("sendExpose", 1))+" ")],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("exposeFileMissing"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.project.exposeFileExists},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-3 text--white",attrs:{"color":"blue accent-1","dark":_vm.enableExposeFollowup,"disabled":!_vm.enableExposeFollowup},on:{"click":function($event){return _vm.$emit('openActionDialog', 'exposeFollowup')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-mail ")]),_vm._v(" "+_vm._s(_vm.$tc("sendExposeFollowup", 1))+" ")],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("exposeFileMissing"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.isTeaserDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-3 text--white",attrs:{"disabled":_vm.isTeaserDisabled,"color":"blue accent-1","dark":!_vm.isTeaserDisabled},on:{"click":function($event){return _vm.$emit('openActionDialog', 'teaser')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-send ")]),_vm._v(" "+_vm._s(_vm.$tc("sendTeaser", 1))+" ")],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("missingTeaserDataTooltip"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.enableExpressionOfInterest},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-3 text--white",attrs:{"disabled":!_vm.enableExpressionOfInterest,"color":"blue accent-1","dark":_vm.enableExpressionOfInterest},on:{"click":function($event){return _vm.$emit('openActionDialog', 'eoi')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-send ")]),_vm._v(" "+_vm._s(_vm.$tc("expressionOfInterest", 1))+" ")],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("exposeWasNotSentYetTooltip"))+" ")])])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{staticClass:"text--white",attrs:{"outlined":"","color":"blue accent-1"},on:{"click":function($event){return _vm.$emit('deleteAll')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$tc("delete", 1))+" ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }