






























































import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import ExpressionOfInterestFormFields from "@/components/common/ExpressionOfInterestFormFields.vue";
import ExpressionOfInterestFormFieldsV2 from "@/components/common/ExpressionOfInterestFormFieldsV2.vue";
import {
  CreateExpressionOfInterest,
  ExpressionOfInterestFormQuestion,
} from "@/entity/ExpressionOfInterest";
import BaseForm from "@/components/form/BaseForm";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ProjectBuyer from "@/entity/ProjectBuyer";
import ExpressionOfInterestVersions from "@/components/project-buyers/expression-of-interest/ExpressionOfInterestVersions.vue";
import ExpressionOfInterestVersionsV2 from "@/components/project-buyers/expression-of-interest/ExpressionOfInterestVersionsV2.vue";
import ExpressionOfInterestFiles from "@/components/common/ExpressionOfInterestFiles.vue";
import BTFile from "@/entity/File";

@Component({
  components: {
    FormCard,
    ExpressionOfInterestFormFields,
    ExpressionOfInterestVersions,
    ExpressionOfInterestFormFieldsV2,
    ExpressionOfInterestVersionsV2,
    ExpressionOfInterestFiles,
  },
})
export default class ExpressionOfInterestForm extends Mixins(BaseForm) {
  @Prop({ required: true, type: Object }) buyer!: ProjectBuyer;
  @Prop({ required: true, type: Number }) projectId!: number;

  questions: ExpressionOfInterestFormQuestion[] = [];
  answers: Record<number, string> = {};
  attachedFiles: BTFile[] | null = null;

  activeTab = 1;
  tabItems = [{ key: "New Version", name: "New Version" }];

  dataForLegacyEoi: CreateExpressionOfInterest = {
    firstName: "",
    name: "",
    profileUrl: "",
    interestedNote: "",
    intentionsNote: "",
    overtakeNote: "",
    sellerRoleNote: "",
    ownFunds: "",
    bankFinancedAcquisitionExperience: "",
  };

  async submitForm() {
    return ProjectBuyersService.addOrEditExpressionOfInterestManually(
      this.projectId,
      this.buyer.id as number,
      this.questions.map((item) => ({
        questionId: item.id,
        value: this.answers[item.id] || "",
      }))
    );
  }

  async loadData(): Promise<void> {
    const resV2 = await ProjectBuyersService.getExpressionOfInterestByBuyerV2(
      this.projectId,
      this.buyer.id as number
    );
    this.questions = resV2.questions.sort((a, b) => a.orderNum - b.orderNum);
    this.attachedFiles = resV2.file;
    if (resV2.answers) {
      this.answers = resV2.answers.reduce(
        (acc, item) => ({ ...acc, [item.questionId]: item.answer }),
        {}
      );
    } else {
      this.answers = resV2.questions.reduce(
        (acc, item) => ({ ...acc, [item.id]: "" }),
        {}
      );
    }

    if (!resV2.answers) {
      // Old Version
      const response =
        await ProjectBuyersService.getExpressionOfInterestByBuyer(
          this.projectId,
          this.buyer.id as number
        );

      if (typeof response === "object") {
        this.dataForLegacyEoi = {
          firstName: response.firstName,
          name: response.name,
          profileUrl: response.profileUrl,
          interestedNote: response.interestedNote,
          intentionsNote: response.intentionsNote,
          overtakeNote: response.overtakeNote,
          sellerRoleNote: response.sellerRoleNote,
          ownFunds: response.ownFunds,
          bankFinancedAcquisitionExperience:
            response.bankFinancedAcquisitionExperience,
        };
        this.tabItems.push({ key: "Legacy", name: "Legacy" });
      }
    }
  }

  created(): void {
    this.handleFormLoad(this.loadData);
  }
}
