

















import { Vue, Component, Watch, Prop } from "vue-property-decorator";

@Component
export default class SectionHeader extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 0 }) selectedAmount!: number;
  @Prop({ type: Boolean, required: true }) value!: boolean;

  isEnabled = false;

  @Watch("isEnabled")
  onIsEnabledChange(isEnabled: boolean): void {
    this.$emit("input", isEnabled);
  }

  get selectedAmountText(): string {
    return `${this.selectedAmount} ${this.$tc(
      "contact",
      this.selectedAmount === 1 ? 1 : 2
    )}`;
  }

  created() {
    this.isEnabled = this.value;
  }
}
