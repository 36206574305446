


































import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import ManualAdd from "@/components/project-buyers-form/ManualAdd.vue";
import ManualAddExternal from "@/components/project-buyers-form/ManualAddExternal.vue";
import RelevantProfiles from "@/components/project-buyers-form/RelevantProfiles.vue";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import Project from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import FromProject from "@/components/project-buyers-form/FromProject.vue";

@Component({
  components: {
    FormCard,
    ManualAdd,
    RelevantProfiles,
    FromProject,
    ManualAddExternal,
  },
})
export default class BuyersListForm extends Mixins(BaseForm) {
  fromManualAdd: number[] = [];
  fromRelevantAdd: number[] = [];
  fromSimilarProjectAdd: number[] = [];
  fromManualAddExternal: number[] = [];
  project: Project | null = null;

  get allSelectedContacts(): Set<number> {
    return new Set([
      ...this.fromManualAdd,
      ...this.fromRelevantAdd,
      ...this.fromSimilarProjectAdd,
      ...this.fromManualAddExternal,
    ]);
  }

  async submitForm(): Promise<void> {
    const existingBuyers = await ProjectBuyersService.saveList(
      this.$route.params.projectId,
      Array.from(this.allSelectedContacts)
    );
    if (existingBuyers.length) {
      this.$snackbarError(
        `${existingBuyers.length} Käufer sind bereits mit dem Projekt bereits verbunden`
      );
    }
  }

  async loadForm(): Promise<void> {
    this.project = await ProjectsService.findOne(this.$route.params.projectId);
  }

  created() {
    this.handleFormLoad(this.loadForm);
  }
}
