
















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { formatPrice } from "@/utils/string";
import { CURRENCY } from "@/constants";
import { Rule } from "@/mixins/RulesMixin";

@Component
export default class CurrencyInput extends Vue {
  @Prop({ default: "" }) value!: string | number | null;
  @Prop({ type: Array }) rules!: Rule[];
  @Prop({ type: Boolean, default: false }) noPrefix!: boolean;
  @Prop({ type: Boolean, default: true }) dense!: boolean;
  @Prop({ type: Boolean, default: true }) outlined!: boolean;

  formattedValue = "";
  touched = false;
  currency = CURRENCY;

  get prefix() {
    if (this.noPrefix) {
      return "";
    }

    return this.currency;
  }

  @Watch("value", { immediate: true })
  onValueChange(value: any): void {
    const isValid = !isNaN(parseInt(value));

    if (isValid) {
      this.formattedValue = formatPrice(value);
    } else {
      this.formattedValue = "";
    }
  }

  toPlainText(value: string): string {
    return value.replace(/'/g, "");
  }

  keyup(): void {
    this.touched = true;
    const value = (this.$el.querySelector("input") as HTMLInputElement).value;
    this.$emit("input", this.toPlainText(value));
  }
}
