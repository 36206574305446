import Axios from "axios";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity, OptionItem } from "@/services/types";
import Project, { FinanceYears, ProjectType } from "@/entity/Project";
import filterQueryParams from "@/services/filterQueryParams";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import {
  ProjectFinance,
  ProjectFinanceImportResponse,
} from "@/entity/ProjectFinance";
import {
  ProjectSales,
  ProjectSalesWithTransactionMultiples,
} from "@/entity/ProjectSales";
import {
  ExpressionOfInterestEditDTO,
  ExpressionOfInterestFormQuestion,
  ExpressionOfInterestVersion,
} from "@/entity/ExpressionOfInterest";

export interface ProjectCountByStatus {
  [key: string]: number;
}

export const PROJECTS_API_URL = "/api/v2/projects";

export default class ProjectsService {
  public static async findOne(projectId: number | string): Promise<Project> {
    const response = await Axios.get(`${PROJECTS_API_URL}/${projectId}`);
    return response.data;
  }

  public static async findOneWithToken(token: string): Promise<Project> {
    const response = await Axios.get(`${PROJECTS_API_URL}/token/${token}`);
    return response.data;
  }

  public static async find(
    query?: TableQuery
  ): Promise<ListResponseEntity<Project>> {
    const response = await Axios.get(PROJECTS_API_URL, {
      params: {
        ...filterQueryParams(query),
      },
    });

    return response.data;
  }

  public static async findAllWithRating(
    projectType: ProjectType,
    includeDeactivated: boolean,
    query: TableQuery
  ): Promise<ListResponseEntity<Project>> {
    const response = await Axios.get(`${PROJECTS_API_URL}/allWithRating`, {
      params: {
        projectType,
        includeDeactivated: !includeDeactivated ? "false" : undefined,
        ...filterQueryParams(query),
      },
    });
    return response.data;
  }

  public static async findAll(): Promise<Project[]> {
    const response = await this.find({
      itemsPerPage: ALL_ITEMS_PER_PAGE,
      sortBy: {
        "projectContact.firstName": false,
      },
    });
    return response.content;
  }

  public static async create(project: any): Promise<Project> {
    const response = await Axios.post(PROJECTS_API_URL, project);
    return response.data;
  }

  public static async update(
    projectId: number | string,
    project: any
  ): Promise<Project> {
    const response = await Axios.put(
      `${PROJECTS_API_URL}/${projectId}`,
      project
    );
    return response.data;
  }

  public static async countByStatus(
    consultantIds: number[]
  ): Promise<ProjectCountByStatus> {
    const response = await Axios.get(`${PROJECTS_API_URL}/countByStatus`, {
      params: {
        consultantIds: consultantIds.toString(),
      },
    });
    return response.data;
  }

  public static async getProjectIdsWhereContactParticipating(
    contactId: number | string
  ): Promise<number[]> {
    const response = await Axios.get(
      `${PROJECTS_API_URL}/projectIdsWhereContactIsParticipant/${contactId}`
    );
    return response.data;
  }

  public static async getProjectOwners(): Promise<OptionItem[]> {
    const response = await Axios.get(`${PROJECTS_API_URL}/owners`);
    return response.data;
  }

  public static async getBalanceSheet(
    projectId: number
  ): Promise<ProjectFinance> {
    const response = await Axios.get(
      `${PROJECTS_API_URL}/${projectId}/finance`
    );
    return response.data;
  }

  public static async uploadBalanceSheet(
    projectId: number,
    file: File
  ): Promise<ProjectFinanceImportResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `${PROJECTS_API_URL}/${projectId}/finance/import`,
      formData
    );
    return response.data;
  }

  public static async getFinanceYears(
    projectId: number
  ): Promise<FinanceYears> {
    const { data } = await Axios.get(
      `${PROJECTS_API_URL}/${projectId}/finance/years`
    );
    return data;
  }

  public static async closeProject(projectId: number): Promise<number> {
    const { data } = await Axios.post(`${PROJECTS_API_URL}/${projectId}/close`);
    return data;
  }

  public static async handleProjectActivity(
    projectId: number
  ): Promise<number> {
    const { data } = await Axios.post(
      `${PROJECTS_API_URL}/${projectId}/activity`
    );
    return data;
  }

  public static async updateSalesData(
    projectId: number,
    projectSales: ProjectSales
  ): Promise<ProjectSales> {
    const { data } = await Axios.post(
      `${PROJECTS_API_URL}/${projectId}/sales`,
      projectSales
    );
    return data;
  }

  public static async getSalesData(
    projectId: number
  ): Promise<ProjectSalesWithTransactionMultiples> {
    const { data } = await Axios.get(`${PROJECTS_API_URL}/${projectId}/sales`);
    return data;
  }

  public static async rejectExpose(
    token: string,
    projectId: number,
    statusId: number,
    comment: string
  ) {
    const { data } = await Axios.patch(
      `${PROJECTS_API_URL}/${projectId}/expose/reject`,
      { token, statusId, comment }
    );
    return data;
  }

  public static async getEoiQuestionsAndAnswerOptions(
    projectId: number,
    versionId: number
  ): Promise<ExpressionOfInterestFormQuestion[]> {
    const { data } = await Axios.get(
      `${PROJECTS_API_URL}/${projectId}/eoi/${versionId}`
    );
    return data;
  }

  public static async getEoiVersions(
    projectId: number
  ): Promise<ExpressionOfInterestVersion[]> {
    const { data } = await Axios.get(
      `${PROJECTS_API_URL}/${projectId}/eoi/versions`
    );
    return data;
  }

  public static async addEoiVersion(projectId: number): Promise<number> {
    const { data } = await Axios.post(
      `${PROJECTS_API_URL}/${projectId}/eoi/versions`
    );
    return data;
  }

  public static async editEoiVersion(
    projectId: number,
    versionId: number,
    questions: ExpressionOfInterestEditDTO[]
  ): Promise<ExpressionOfInterestFormQuestion[]> {
    const { data } = await Axios.put(
      `${PROJECTS_API_URL}/${projectId}/eoi/version/${versionId}`,
      questions
    );
    return data;
  }
}
